//员工详情
<template>
  <div class="staffDetails">
    <div class="staffDetails_box b-c-f">
      <div class="">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/personage/staff' }">员工管理 </el-breadcrumb-item>
          <el-breadcrumb-item>详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="staffDetails_head flex">
        <div class="staffDetails_head_img flexs">
          <img :src="details.image" alt="" />
          <span>{{ details.name }}</span>
        </div>
        <ul class="staffDetails_head_ul flexs" v-if="details.user_status == 1">
          <li @click="changeStaff(0)">
            <el-button>修改</el-button>
          </li>
           <li @click="changeStaff(1)">
            <el-button>离职</el-button>
          </li>
           <li @click="changeStaff(2)">
            <el-button v-if="details.position == 2 || details.position == 4">调店</el-button>
          </li>
        </ul>
      </div>
      <div class="staffDetails_main">
        <ul class="staffDetails_ul">
          <li>
            <div class="staffDetails_ul_head">
              <span>个人信息</span>
            </div>
            <div class="staffDetails_ul_list">
              <div class="staffDetails_ul_li flexs">
                <div class="staffDetails_ul_txt">姓名：</div>
                <div class="staffDetails_ul_name">{{ details.name }}</div>
              </div>
              <div class="staffDetails_ul_li flexs">
                <div class="staffDetails_ul_txt">性别：</div>
                <div class="staffDetails_ul_name">{{ details.sex }}</div>
              </div>
              <div class="staffDetails_ul_li flexs">
                <div class="staffDetails_ul_txt">手机号：</div>
                <div class="staffDetails_ul_name">{{ details.mobile }}</div>
              </div>
              <div class="staffDetails_ul_li flexs">
                <div class="staffDetails_ul_txt">身份证号：</div>
                <div class="staffDetails_ul_name">{{ details.identity }}</div>
              </div>
              <div class="staffDetails_ul_li flexs">
                <div class="staffDetails_ul_txt">年龄：</div>
                <div class="staffDetails_ul_name">{{ details.age }}</div>
              </div>
              <div class="staffDetails_ul_li flexs">
                <div class="staffDetails_ul_txt">生日：</div>
                <div class="staffDetails_ul_name">{{ details.birthday }}</div>
              </div>
              <div class="staffDetails_ul_li flexs">
                <div class="staffDetails_ul_txt">现住：</div>
                <div class="staffDetails_ul_name">
                  {{ details.xian_address }}
                </div>
              </div>
              <div class="staffDetails_ul_li flexs">
                <div class="staffDetails_ul_txt">籍贯：</div>
                <div class="staffDetails_ul_name">
                  {{ details.native_place }}
                </div>
              </div>
              <div class="staffDetails_ul_li flexs">
                <div class="staffDetails_ul_txt">直系亲属：</div>
                <div class="staffDetails_ul_name">{{ details.relatives }}</div>
              </div>
              <div
                class="staffDetails_ul_li flexs"
                v-for="(item, index) in details.relatives_mobile"
                :key="index"
              >
                <div class="staffDetails_ul_txt">电话：</div>
                <div class="staffDetails_ul_name">{{ item }}</div>
              </div>
            </div>
          </li>
          <li>
            <div class="staffDetails_ul_head">
              <span>职位信息</span>
            </div>
            <div class="staffDetails_ul_list">
              <div class="staffDetails_ul_li flexs">
                <div class="staffDetails_ul_txt">工号：</div>
                <div class="staffDetails_ul_name">{{ details.id }}</div>
              </div>
              <div class="staffDetails_ul_li flexs">
                <div class="staffDetails_ul_txt">职位：</div>
                <div class="staffDetails_ul_name">
                  {{ getPosition(details.position) }}
                </div>
              </div>
              <div class="staffDetails_ul_li flexs">
                <div class="staffDetails_ul_txt">门店：</div>
                <div class="staffDetails_ul_name">{{ details.dian_name }}</div>
              </div>
              <div class="staffDetails_ul_li flexs">
                <div class="staffDetails_ul_txt">经理：</div>
                <div class="staffDetails_ul_name">{{ details.identity }}</div>
              </div>
              <div class="staffDetails_ul_li flexs">
                <div class="staffDetails_ul_txt">入职时间：</div>
                <div class="staffDetails_ul_name">
                  {{ details.position_time }}
                </div>
              </div>
              <div class="staffDetails_ul_li flexs">
                <div class="staffDetails_ul_txt">薪资模式：</div>
                <div class="staffDetails_ul_name">{{ details.salary_name }}</div>
              </div>
              <div class="staffDetails_ul_li flexs">
                <div class="staffDetails_ul_txt">在职状态：</div>
                <div class="staffDetails_ul_name">
                  {{ details.user_status == 0 ? "离职" : "在职" }}
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="staffDetails_ul_head">
              <span>其他信息</span>
            </div>
            <div class="staffDetails_ul_list">
              <div class="staffDetails_ul_li fl">
                <div class="staffDetails_ul_txt">身份证正面：</div>
                <div class="staffDetails_ul_img flexs" v-if="details.identity_image_z">
                  <img  :src="details.identity_image_z" alt="" />
                </div>
              </div>
              <div class="staffDetails_ul_li fl">
                <div class="staffDetails_ul_txt">身份证反面：</div>
                <div class="staffDetails_ul_img flexs" v-if="details.identity_image_f">
                  <img :src="details.identity_image_f" alt="" />
                </div>
              </div>
              <div class="staffDetails_ul_li fls fl">
                <div class="staffDetails_ul_txt">劳动合同：</div>
                <div class="staffDetails_ul_img flexs">
                  <img
                    :src="item"
                    alt=""
                    v-for="(item, index) in details.contract_images"
                    :key="index"
                  />
                  
                </div>
              </div>
              <div class="staffDetails_ul_li fls fl">
                <div class="staffDetails_ul_txt">其他：</div>
                <div class="staffDetails_ul_img flexs">
                  <img
                    :src="item"
                    alt=""
                    v-for="(item, index) in details.images"
                    :key="index"
                  />
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="staffDetails_ul_head">
              <span>登录信息</span>
            </div>
            <div class="staffDetails_ul_list">
              <div class="staffDetails_ul_li flexs">
                <div class="staffDetails_ul_txt">登录账号：</div>
                <div class="staffDetails_ul_name">{{ details.mobile }}</div>
              </div>
              <div class="staffDetails_ul_li flexs">
                <div class="staffDetails_ul_txt">登录密码：</div>
                <div class="staffDetails_ul_name">{{ details.password }}</div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
     <!-- 调店弹框 S -->
    <staff-pop ref="staff" @succeed="changeArea"></staff-pop>
    <!--  调店弹框弹框 E -->
  </div>
</template>

<script>
import StaffPop from '../../components/StaffPop'
export default {
  components: { StaffPop},
  data() {
    return {
      id: null, //详情id
      details: {}, //
    };
  },
  methods: {
    changeArea () {
      this.$router.go(-1)
    },
    changeStaff (index) {
      if (index == 0) {
         this.$router.push({ path: "/personage/AffairsStaffAdd",query:{id:this.id} });
      } else if (index == 1) {
        this.$confirm("是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.getStaffList();
          this.$axios.liZhi({ id: this.id }).then((res) => {
            this.$message({
              type: "success",
              message: res.msg,
            });
          });
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "已取消删除",
          // });
        });
      } else {
        this.$refs.staff.open(this.id)
      }
    },
    getDetails() {
      this.$axios.staffDetail({ id: this.id }).then((res) => {
        this.details = res.data;
        let images = [];
        res.data.images.forEach((element) => {
          if (element) {
            images.push(element);
          }
        });
        res.data.images = images;
        let contract_images = [];
        res.data.contract_images.forEach((element) => {
          if (element) {
            contract_images.push(element);
          }
        });
        res.data.contract_images = contract_images;
      });
    },
    getPosition(type) {
      let txt = "";
      switch (type) {
        case 1:
          txt = "区域总监";
          break;
        case 2:
          txt = "店长";
          break;
        case 3:
          txt = "经理";
          break;
        case 4:
          txt = "经纪人";
          break;
        case 5:
          txt = "人事";
          break;
        default:
          break;
      }
      return txt;
    },
  },
  created() {
    this.id = this.$route.query.id;
    this.getDetails();
  },
};
</script>

<style lang="less" scoped>
.staffDetails_box {
  padding: 30px;
  .staffDetails_head {
    padding: 40px 0;
    .staffDetails_head_img {
      img {
        width: 64px;
        height: 64px;
        border-radius: 50%;
      }
      span {
        color: #333333;
        font-size: 18px;
        margin-left: 15px;
      }
    }
    .staffDetails_head_ul {
      li {
        /deep/ .el-button {
          display: flex;
          padding: 0;
          align-items: center;
          margin-left: 15px;
          width: 80px;
          height: 36px;
          font-size: 16px;
          justify-content: center;
          border-radius: 4px;
        }
        &:first-child {
          /deep/ .el-button {
            color: #ffffff;
            background: #3273f6;
          }
        }
        &:nth-child(2) {
          /deep/ .el-button {
            color: #ff5151;
            border: none;
            background: #fddfdf;
          }
        }
        &:last-child {
          /deep/ .el-button {
            color: #3273f6;
            background: #ffffff;
            border: 1px solid #3273f6;
          }
        }
      }
    }
  }
}
.staffDetails_ul {
  padding-left: 10px;
  li {
    margin-bottom: 40px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .staffDetails_ul_head {
    span {
      padding-left: 14px;
      position: relative;
      &::before {
        top: 50%;
        left: 0;
        content: "";
        transform: translateY(-50%);
        position: absolute;
        width: 4px;
        height: 20px;
        background: #3273f6;
      }
    }
  }
  .staffDetails_ul_list {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }
  .staffDetails_ul_li {
    margin-top: 20px;
    width: 50%;
    .staffDetails_ul_txt {
      color: #999999;
      font-size: 16px;
    }
    .staffDetails_ul_name {
      color: #333333;
      font-size: 16px;
    }

    .staffDetails_ul_img {
      position: relative;
      img {
        width: 180px;
        height: 120px;
        border-radius: 4px;
      }
      .cancel {
        width: 24px;
        height: 24px;
        position: absolute;
        top: -12px;
        right: -12px;
        cursor: pointer;
      }
    }
  }
  .fl {
    display: flex;
    .staffDetails_ul_txt {
      width: 100px;
    }
  }
  .fls {
    width: 100%;
    .staffDetails_ul_img {
      img {
        width: 120px;
        height: 120px;
        margin-right: 20px;
      }
    }
  }
}
</style>
